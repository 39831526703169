<template>
  <div>
    <!--  <van-nav-bar title="办事进度" left-text="返回" left-arrow @click-left="toTopPage()">-->
    <!--  </van-nav-bar>-->
    <van-tabs v-model="active" animated color="#0090ff">
      <van-tab title="申请事项">
        <div class="table" v-if="workData.doDatas.length > 0">
          <div class="table-header">
            <div>事项名称</div>
            <div>开始时间</div>
            <div>当前状态</div>
          </div>
          <div class="table-content">
            <div
              class="table-content-item"
              v-for="(item, index) in workData.doDatas"
              :key="index"
            >
              <div>
                {{ item.MatterName }}
              </div>
              <div>{{ item.StartDate }}</div>
              <div>{{ item.MatterStatus }}</div>
            </div>
          </div>
        </div>
        <van-empty description="暂无事项" v-else></van-empty>
      </van-tab>
      <van-tab title="应办事项">
        <div class="table" v-if="workData.notDatas.length > 0">
          <div class="table-header">
            <div>事项名称</div>
            <div>截止时间</div>
          </div>
          <div class="table-content">
            <div
              class="table-content-item"
              v-for="(item, index) in workData.notDatas"
              :key="index"
            >
              <div>
                {{ item.MatterName }}
              </div>
              <div>{{ item.EndDate }}</div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无事项"></van-empty>
      </van-tab>
      <van-tab title="办结事项">
        <div class="table" v-if="workData.finishDatas.length > 0">
          <div class="table-header">
            <div>事项名称</div>
            <div>申请人</div>
            <div>办理结果</div>
          </div>
          <div class="table-content">
            <div
              class="table-content-item"
              v-for="(item, index) in workData.finishDatas"
              :key="index"
            >
              <div>
                {{ item.MatterName }}
              </div>
              <div>{{ item.ApplyName }}</div>
              <div>{{ item.MatterResult }}</div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无事项"></van-empty>
      </van-tab>
    </van-tabs>
    <!--  {{workData}}-->
  </div>
</template>

<script>
import { zjApi } from '@/api/index'

export default {
  name: 'workProgress',
  data() {
    return {
      active: 0,
      cinemaId: '',
      workData: {
        doDatas: [],
        notDatas: [],
        finishDatas: [],
      },
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id
    this.getWorks()
  },
  methods: {
    toTopPage() {
      window.history.back()
    },
    getWorks() {
      const userCode = this.$store.state.userInfo.mobile
      const queryString = `{"UserCode":"${userCode}","CmdIndex":"101401","cmdArgs":["${this.cinemaId}"]}`;
      const queryJson = JSON.parse(queryString);
      zjApi.getPs(queryJson).then((r) => {
        const { Success, data, Message } = r
        this.workData = data
      })
    },
  },
}
</script>

<style scoped lang="less">
.table {
  font-size: 0.4rem;

  &-header {
    background: #f2f2f2;
    display: flex;
    height: 1rem;
    align-items: center;

    > div {
      flex: 2;
      text-align: center;

      &:nth-child(2) {
        flex: 1;
      }

      &:nth-child(3) {
        flex: 1;
      }
    }
  }

  &-content {
    &-item {
      &:nth-child(2n) {
        background-color: #f6f7f8;
      }

      display: flex;
      height: 1.2rem;
      align-items: center;

      > div {
        flex: 2;
        text-align: center;

        display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
        -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
        overflow: hidden; /*超出的文本隐藏*/
        text-overflow: ellipsis; /* 溢出用省略号*/
        -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/

        &:nth-child(2) {
          flex: 1;
        }

        &:nth-child(3) {
          flex: 1;
        }
      }
    }
  }
}
</style>
